import { API_ROOT } from "../components/constants/api";
import { encodeObjectWith } from "../utils/utils";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const UPDATE_REGISTRATION_STATUS = "UPDATE_REGISTRATION_STATUS";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REGISTRATION_STATUS:
      return {
        ...state,
        status: action.payload.status
      };
    default:
      return state;
  }
};

export function updateRegistrationStatus(status) {
  return dispatch => {
    dispatch({
      type: UPDATE_REGISTRATION_STATUS,
      payload: {
        status: status
      }
    });
  };
}

export function reset() {
  updateRegistrationStatus(null);
}

export function registerUser(email, firstName, lastName, password, accessCode) {
  return dispatch => {
    let requiredParams = {
      email_id: email,
      first_name: firstName,
      last_name: lastName,
      password: password
    };
    let optionalParams = accessCode ? { access_code: accessCode } : {};
    let params = Object.assign({}, requiredParams, optionalParams);
    let encodedParams = encodeObjectWith(params, "&");

    fetch(API_ROOT + "register?" + encodedParams, {
      method: "POST"
    })
      .then(response => {
        if (response.status >= 400) {
          throw new Error("failed");
        } else {
          return response.json();
        }
      })
      .then(json => {
        dispatch(updateRegistrationStatus(REGISTER_SUCCESS));
      })
      .catch(error => {
        dispatch(updateRegistrationStatus(REGISTER_FAILED));
      });
  };
}
