import React, { Component } from "react";
import AddAccess from "./add-access";
import Modal from "../modal/modal";
import { css } from "emotion";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  requestAccessForUser,
  updateAccesRequestStatus,
  ACCESS_REQUEST_SUCCESS
} from "../../modules/access";

class AddAccessContainer extends Component {
  onAddAccessSubmit = (email, password, accessCode) => {
    this.props.requestAccessForUser(email, password, accessCode);
  };

  componentWillReceiveProps(props) {
    let status = props.access.status;
    if (status === ACCESS_REQUEST_SUCCESS) {
      props.goToSuccessPage();
      props.updateAccesRequestStatus(null);
    }
  }

  render() {
    const modalSubTitle = css({
      fontFamily: "OpenSans",
      fontSize: "16px",
      lineHeight: 1.5,
      letterSpacing: "0.5px",
      display: "block",
      marginTop: "8px",
      marginBottom: "25px",
      color: "#112138"
    });
    return (
      <div className={container}>
        <Modal
          title="Welcome"
          subtitle="Add your access code"
          subTitleStyle={modalSubTitle}
        >
          <AddAccess
            onSubmit={this.onAddAccessSubmit}
            status={this.props.access.status}
            inProgress={this.props.access.inProgress}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  access: state.access
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestAccessForUser,
      updateAccesRequestStatus,
      goToHomePage: () => push("/"),
      goToSuccessPage: () => push("/access-granted")
    },
    dispatch
  );

const container = css({
  backgroundColor: "#202e38",
  position: "relative"
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAccessContainer);
