import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import app from './app';
import register from './register';
import access from './access';

export default combineReducers({
  routing: routerReducer,
  app,
  register,
  access
});
