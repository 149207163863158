import { API_ROOT } from '../components/constants/api';
import { requestLogout } from '../utils/logout';
export const ACCESS_REQUEST = 'ACCESS_REQUEST';
export const ACCESS_REQUEST_SUCCESS = 'ACCESS_REQUEST_SUCCESS';

export const UPDATE_ACCESS_REQUEST_STATUS = 'UPDATE_ACCESS_REQUEST_STATUS';

export const ERROR_MSG = {
  LOGIN_FAILURE: 'Login Failed',
  TOKEN_EXPIRED: 'Token expired',
  ACCESS_REQUEST_FAILED: 'Access request failed',
  SERVER_ERROR: 'SERVER_ERROR'
};

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACCESS_REQUEST_STATUS:
      return {
        ...state,
        status: action.payload.status,
        inProgress: action.payload.inProgress
      };
    default:
      return state;
  }
};

export function updateAccesRequestStatus(status, inProgress = true) {
  return dispatch => {
    dispatch({
      type: UPDATE_ACCESS_REQUEST_STATUS,
      payload: {
        status: status,
        inProgress: inProgress
      }
    });
  };
}

export function reset() {
  updateAccesRequestStatus(null, false);
}

function login(username, password) {
  const url = API_ROOT + 'login';
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_email: username,
      password: password,
      ui: 12
    })
  }).then(response => {
    if (response.status === 500) {
      throw new Error(ERROR_MSG.LOGIN_FAILURE);
    } else if (response.status >= 400) {
      throw new Error(ERROR_MSG.SERVER_ERROR);
    } else {
      return response.json();
    }
  });
}

function requestAccess(accessCode, token) {
  const url = API_ROOT + 'addaccess';
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Xauthtoken: token
    },
    body: JSON.stringify({
      access_code: accessCode
    })
  }).then(response => {
    if (response.status === 403) {
      throw new Error(ERROR_MSG.TOKEN_EXPIRED);
    } else if (response.status >= 400) {
      throw new Error(ERROR_MSG.ACCESS_REQUEST_FAILED);
    } else {
      return {
        response: response.json(),
        token
      };
    }
  });
}

export function requestAccessForUser(email, password, accessCode) {
  return dispatch => {
    dispatch(updateAccesRequestStatus(ACCESS_REQUEST, true));
    login(email, password)
      .then(response => {
        const token = response.token;
        return requestAccess(accessCode, token);
      })
      .then(data => {
        const response = data.response;
        const token = data.token;
        if (response.result === 'failure') {
          throw new Error(ERROR_MSG.ACCESS_REQUEST_FAILED);
        } else {
          dispatch(updateAccesRequestStatus(ACCESS_REQUEST_SUCCESS, false));
        }
        return new Promise(resolve => {
          resolve(token);
        });
      })
      .then(token => requestLogout(token))
      .catch(error => {
        const errorMessage = error.message;
        if (errorMessage === ERROR_MSG.LOGIN_FAILURE) {
          dispatch(updateAccesRequestStatus(ERROR_MSG.LOGIN_FAILURE, false));
        } else if (errorMessage === ERROR_MSG.TOKEN_EXPIRED) {
          dispatch(updateAccesRequestStatus(ERROR_MSG.LOGIN_FAILURE, false));
        } else if (errorMessage === ERROR_MSG.ACCESS_REQUEST_FAILED) {
          dispatch(
            updateAccesRequestStatus(ERROR_MSG.ACCESS_REQUEST_FAILED, false)
          );
        } else {
          dispatch(
            updateAccesRequestStatus(ERROR_MSG.ACCESS_REQUEST_FAILED, false)
          );
        }
      });
  };
}
