import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import FybrLogo from '../../fybr-logo.svg';

export default class AppHeader extends Component {
  render() {
    return (
      <div>
        <div className={appHeaderClass}>
          <div className={navBar}>
            <Link to="/">
              <img className={logo} alt="Fybr Logo" src={FybrLogo} />
            </Link>
          </div>
        </div>
        <div className={marginBar} />
      </div>
    );
  }
}

const appHeaderClass = css({
  background: '#202e38',
  color: '#FFFFFF',
  position: 'fixed',
  padding: '20px',
  fontSize: '15px',
  fontWeight: '500',
  top: '0',
  height: '60px',
  width: '100%',
  boxSizing: 'border-box',
  zIndex: '10'
});

const navBar = css({
  display: 'flex',
  width: '100%',
  margin: '0px',
  justifyContent: 'center'
});

const logo = css({
  display: 'block'
});

const marginBar = css({
  height: '60px',
  width: '100%'
});

AppHeader.propTypes = {
  button: PropTypes.object
};

AppHeader.defaultProps = {
  button: {}
};
