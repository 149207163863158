import { API_ROOT } from '../components/constants/api';
import { ERROR_MSG } from '../modules/access';

export function requestLogout(token) {
  return fetch(API_ROOT + 'logout.json', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Xauthtoken: token
    }
  }).then(response => {
    if (response.status !== 201) {
      throw new Error(ERROR_MSG.SERVER_ERROR);
    }
    return response;
  });
}
