import React, { Component } from "react";
import Register from "./register";
import Modal from "../modal/modal";
import { css } from "emotion";

import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  registerUser,
  updateRegistrationStatus,
  REGISTER_SUCCESS,
  REGISTER_FAILED
} from "../../modules/register";

class RegisterContainer extends Component {
  onRegisterSubmit = (email, firstName, lastName, password, accessCode) => {
    this.props.registerUser(email, firstName, lastName, password, accessCode);
  };

  componentWillReceiveProps(props) {
    let status = props.register.status;
    if (status === REGISTER_SUCCESS) {
      props.goToSuccessPage();
      props.updateRegistrationStatus(null);
    } else if (status === REGISTER_FAILED) {
      props.goToErrorPage();
      props.updateRegistrationStatus(null);
    }
  }

  render() {
    const modalSubTitle = css({
      fontFamily: "OpenSans",
      fontSize: "16px",
      lineHeight: 1.5,
      letterSpacing: "0.5px",
      display: "block",
      marginTop: "8px",
      marginBottom: "25px",
      color: "#112138"
    });

    return (
      <div className={registerContainer}>
        <Modal
          title="Welcome"
          subtitle="Create your Fybr Account"
          subTitleStyle={modalSubTitle}
        >
          <Register onSubmit={this.onRegisterSubmit} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  register: state.register
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      registerUser,
      updateRegistrationStatus,
      goToHomePage: () => push("/"),
      goToErrorPage: () => push("/error"),
      goToSuccessPage: () => push("/success")
    },
    dispatch
  );

const registerContainer = css({
  backgroundColor: "#202e38",
  position: "relative"
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterContainer);
