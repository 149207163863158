import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { css } from 'emotion';

class TextInput extends React.Component {
    static defaultProps = {
        type: 'text',
        labelType: 'static', // static, floating
        required: false,
        value: '',
        onKeyPress: function () {},
        onChange: function () {},
        onFocus: function () {},
        onBlur: function () {}
    };

    static propTypes = {
        type: PropTypes.string,
        placeholder: PropTypes.string,
        label: PropTypes.string,
        labelType: PropTypes.string,
        className: PropTypes.string,
        required: PropTypes.bool,
        refs: PropTypes.string,
        value: PropTypes.string,
        onKeyPress: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func
    };

    constructor(props){
        super(props);
        this.state = {
            text:'',
            focus:0
        };
    }

    handleOnChange(e) {
        this.setState({text: e.target.value});
        this.props.onChange(e, e.target.value);
    }

    onKeyPress(e) {
      this.props.onKeyPress(e);
    }

    onBlur(e){
        this.setState({focus:0});
        this.props.onBlur(e);
    }

    onFocus(e){
        this.setState({focus:1});
        this.props.onFocus(e);
    }

    renderInputField() {
      let {
          type,
          placeholder,
          labelType,
          value,
          required
      } = this.props;

      let text = this.state.text !== "" ? this.state.text : value;
      if(type === 'password') {
        return(
          <input type={type} value={text} ref={this.props.refs}
            required={required} onKeyPress={this.onKeyPress.bind(this)}
            placeholder={labelType !== 'floating' ? placeholder : ''}
            onBlur={this.onBlur.bind(this)}
            onFocus={this.onFocus.bind(this)}
            onChange= {this.handleOnChange.bind(this)}
            className={inputClass}
            autoComplete="current-password"
            />
        );
      } else {
        return(
          <input type={type} value={text} ref={this.props.refs}
            required={required} onKeyPress={this.onKeyPress.bind(this)}
            placeholder={labelType !== 'floating' ? placeholder : ''}
            onBlur={this.onBlur.bind(this)}
            onFocus={this.onFocus.bind(this)}
            onChange= {this.handleOnChange.bind(this)}
            className={inputClass}
            autoComplete="username"
            />
        );
      }
    }


    render() {
      let {
          label,
          labelType,
          value,
          className
      } = this.props;

      let text = this.state.text !== "" ? this.state.text : value;

        let classes = classnames({
            [`${textInput}`]:true,
            [className]: !!className,
            'floating-label': labelType === 'floating',
            'floating-label-active': labelType === 'floating' && this.state.focus
        });
        let labelClass = classnames({
            [`${textInputLabel}`] : true,
            [`${floatingLabel}`] : labelType === 'floating',
            [`${floatingLabelActive}`] : labelType === 'floating' && (this.state.focus || text)

        })

        let labelJSX = <span className={labelClass}>{label}</span>;
        return (
            <label className={classes} >
                {labelType === 'static' ? labelJSX : null}
                {this.renderInputField()}
                {labelType === 'floating' ? labelJSX : null}
            </label>
        );
    }
}

const textInput = css({
    position: 'relative',
    display: 'block',
    height: '52px',
    width: '100%',
    textAlign: 'left',
});
const textInputLabel = css({
    display: 'inline-block',
    marginBottom: '8px',
    fontSize: '12px',
});
const floatingLabel = css({
    position: 'absolute',
    left: 0,
    top: 0,
    padding: '17px 12px',
    margin: 0,
    fontSize: '14px',
    color: 'rgba(0,0,0,0.27)',
    transform: 'scale(1)',
    transition: 'transform .3s, transform-orign .5s',
    transformOrigin: '12px -45%',
});
const floatingLabelActive = css({
    transform: 'scale(0.7)',
    color: '#6463ca'
});

const inputClass = css({
    width: '100%',
    height: '52px',
    boxSizing: 'border-box',
    fontSize: '12px',
    padding: '10px 12px',
    background: '#FFFFFF',
	  border: 'solid 1px #dbdbdb',
    borderRadius: '2px',

    '&:focus' : {
        outline: 'none',
        border: 'solid 1.5px #6463ca'
    }
});

export default TextInput;
