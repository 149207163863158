import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import PageNotFound from "../pages/page-not-found";
import RegisterContainer from "../register/register-container";
import AddAccessContainer from "../add-access/add-access-container";
import AccessGranted from "../add-access/add-access-success";
import RegisterError from "../register/register-error";
import RegisterSuccess from "../register/register-success";
import { withRouter } from "react-router";
import AppHeader from "../app-header/app-header";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";

import "./app.css";

class App extends Component {
  render() {
    let homeBtn = {
      name: "Home",
      func: this.props.goToHomePage
    };

    return (
      <div className={page}>
        <header className={header}>
          <AppHeader button={homeBtn} />
        </header>
        <main className={main}>
          <Switch>
            <Route exact path="/" component={RegisterContainer} />
            <Route exact path="/error" component={RegisterError} />
            <Route exact path="/success" component={RegisterSuccess} />
            <Route exact path="/addaccess" component={AddAccessContainer} />
            <Route exact path="/access-granted" component={AccessGranted} />
            <Route path="*" exact={true} component={PageNotFound} />
          </Switch>
        </main>
        <footer className={footer}>
          <div className={footerContainer}>
            <div className={footerClass}>
              Copyright &copy; 2017 Fybr. All rights reserved.
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
const page = css({
  backgroundColor: "#202e38",
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh"
});

const header = css({
  display: "flex",
  flexGrow: "1"
});

const main = css({
  display: "flex",
  flexGrow: "5",
  alignSelf: "center"
});

const footer = css({
  display: "flex",
  flexGrow: "1",
  alignSelf: "center"
});

const footerClass = css({
  fontFamily: "OpenSans",
  fontSize: "14px",
  color: "#BDBDBD",
  letterSpacing: "0.5px",
  lineHeight: "21px",
  width: "100%",
  textAlign: "center"
});

const footerContainer = css({
  minHeight: "75px",
  marginTop: "25px"
});

const mapStateToProps = state => ({
  register: state.register,
  app: state.app
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToHomePage: () => push("/")
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
