import React from 'react';
import PropTypes from 'prop-types';

import errorIcon from '../assets/error-icon.svg';
import { css } from 'emotion';


export default class ErrorDialog extends React.Component {
  static defaultProps = {
    content: 'Sorry! Your attempt to login has failed. Please try again.',
    children: []
  };

  static propTypes = {
    content: PropTypes.string,
    children: PropTypes.array
  };

  render() {
    const container = css({
      fontSize: '14px',
      letterSpacing: '0px',
      padding: '10px',
      background: '#FFFFFF',
      boxShadow: '0 1px 4px 0 rgba(255,255,255,1)',
      fontFamily: "Roboto",
      color: '#0152cc',
      lineHeight: '21px',
      display: 'flex',
      flexDirection: 'row'
    })
    const errorContainer = css({
      display: 'flex',
      flexDirection: 'row'
    });
    const iconStyle = css({
      marginTop: 'auto',
      marginRight: '10px',
      marginBottom: 'auto'
    });
    const textStyle = css({
      marginTop: 'auto',
      marginBottom: 'auto',
      display: 'flex'
    });

    let { content, children } = this.props;
    return (
      <div className={container}>
        {children.length > 0 ? (
          children
        ) : (
          <span className={errorContainer}>
            <img className={iconStyle} src={errorIcon} alt="" />
            <span className={textStyle}>{content}</span>
          </span>
        )}
      </div>
    );
  }
}
