import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '../textfield/textfield';
import Button from '../button/button';
import { css } from 'emotion';
import loading from '../../assets/loading.gif';
import error from '../../assets/error-icon.svg';
import ErrorDialog from '../error-dialog';

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmedPassword: '',
  accessCode: '',
  makingServerRequest: false,
  validInput: true,
  emailValid: true,
  passwordsMatch: true,
  isPasswordSecure: true
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.formPreventDefault = this.formPreventDefault.bind(this);
    this.state = initialState;
    this.handleFirstName = this.handleFirstName.bind(this);
    this.handleLastName = this.handleLastName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    this.handleAccessCode = this.handleAccessCode.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
  }

  componentWillUnmount(props) {
    this.setState(initialState);
  }

  hasValidInputs() {
    let fields = ['email', 'firstName', 'lastName', 'password', 'confirmedPassword'];
    let emptyFields = fields.filter(field => {
      return this.state[field].trim() === '';
    });
    return emptyFields.length === 0;
  }

  handleFirstName(event) {
    this.setState({ firstName: event.target.value, validInput: true });
  }

  handleLastName(event) {
    this.setState({ lastName: event.target.value, validInput: true });
  }

  validateEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleEmail(event) {
    const email = event.target.value;
    const emailValid = this.validateEmail(email);
    this.setState({ email, emailValid , validInput: true});
  }

  isPasswordSecure(password) {
    // eslint-disable-next-line
    const mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    return mediumRegex.test(password);
  }

  handlePassword(event) {
    const isPasswordSecure = this.isPasswordSecure(event.target.value);
    this.setState({
      password: event.target.value,
      validInput: true,
      isPasswordSecure
    });
  }

  doPasswordsMatch() {
    let { confirmedPassword, password } = this.state;
    return confirmedPassword === password;
  }

  handleConfirmPassword(event) {
    const input = event.target.value;
    const doPasswordsMatch = input === this.state.password;
    this.setState({
      confirmedPassword: input,
      passwordsMatch: doPasswordsMatch,
      validInput: true
    });
  }

  handleAccessCode(event) {
    this.setState({ accessCode: event.target.value });
  }

  handleRegister(e) {
    this.submitDetails();
  }

  submitDetails() {
    const errorMessage = this.getErrorMessage();
    const hasValidInputs = this.hasValidInputs();
    this.setState({
      validInput: hasValidInputs
    });
    if (hasValidInputs && errorMessage === null) {
      this.setState({
        makingServerRequest: true
      });
      this.props.onSubmit(
        this.state.email.toLowerCase(),
        this.state.firstName.toLowerCase(),
        this.state.lastName.toLowerCase(),
        this.state.password,
        this.state.accessCode
      );
    }
  }

  handleKeyPress(target) {
    if (target.charCode === 13) {
      this.submitDetails();
    }
  }

  getErrorMessage() {
    const { validInput, emailValid, passwordsMatch, isPasswordSecure } = this.state;
    if (!validInput) {
      return 'Please enter all the fields';
    }
    if (!emailValid) {
      return 'Please enter a valid email address';
    }
    if (!passwordsMatch) {
      return 'Your passwords do not match';
    }
    if(!isPasswordSecure) {
      return 'Your password should be 8 characters or longer. It should have at least 1 lower and upper case alphabets, 1 numeral and 1 special character.'
    }
    return null;
  }

  renderRegisterError(message = 'Please enter all the fields.') {
    const iconStyle = css({
      marginTop: 'auto',
      marginRight: '10px',
      marginBottom: 'auto'
    });
    const textStyle = css({
      marginTop: 'auto',
      marginBottom: 'auto',
      display: 'flex',
      textAlign: 'left'
    });
    const container = css({
      display: 'flex'
    });
    if (message === null) return null;
    return (
      <div className={container}>
        <ErrorDialog>
          <img src={error} className={iconStyle} alt="" />
          <div className={textStyle}>{message}</div>
        </ErrorDialog>
      </div>
    );
  }

  renderRegisterButton() {
    const containerStyle = css({
      margin: '15',
      display: 'flex',
      flex: '1',
      alignSelf: 'center',
      width: '100%'
    });

    const container = css({
      backgroundColor: '#0151cb',
      width: '100%'
    });

    const buttonContainer = css({
      width: '100%'
    });

    const showLoader = this.state.makingServerRequest;
    const loader = <img src={loading} alt="loading" className={loadingClass} />;
    const registerButton = (
      <Button
        label="Register"
        buttonClickCallback={this.handleRegister}
        text="Register"
        btnClassName={container}
        buttonContainerStyle={buttonContainer}
      />
    );
    return (
      <div className={containerStyle}>
        {showLoader ? loader : registerButton}
      </div>
    );
  }

  renderNameFields() {
    const namesStyle = css({
      display: 'flex',
      flexDirection: 'row'
    });

    const left = css({
      display: 'flex',
      flex: '1'
    });

    const right = css({
      display: 'flex',
      flex: '1'
    });

    return (
      <div className={namesStyle}>
        <div className={left}>
          <TextField
            labelType="floating"
            label="First Name"
            value=""
            onChange={this.handleFirstName}
          />
        </div>
        <div className={right}>
          <TextField
            labelType="floating"
            label="Last Name"
            value=""
            onChange={this.handleLastName}
          />
        </div>
      </div>
    );
  }

  formPreventDefault(e) {
    e.preventDefault();
  }

  render() {
    return (
      <div>
        <form onSubmit={this.formPreventDefault}>
          <div className={divStyle}>
            {this.renderRegisterError(this.getErrorMessage())}
            {this.renderNameFields()}
            <br />
            <TextField
              labelType="floating"
              label="Email address"
              value=""
              onChange={this.handleEmail}
            />
            <br />
            <TextField
              type="password"
              labelType="floating"
              label="Password"
              value=""
              onChange={this.handlePassword}
            />
            <br />
            <TextField
              type="password"
              labelType="floating"
              label="Confirm Password"
              value=""
              onChange={this.handleConfirmPassword}
            />
            <br />
            <TextField
              labelType="floating"
              label="Access code (Optional)"
              value=""
              onChange={this.handleAccessCode}
            />
            <br />
            {this.renderRegisterButton()}
          </div>
        </form>
      </div>
    );
  }
}

const divStyle = css({
  textAlign: 'center',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column'
});

const loadingClass = css({
  height: '50px',
  width: '50px'
});

Register.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
};

export default Register;
