import React, { Component } from "react";
import Button from "../button/button";
import TextField from "../textfield/textfield";

import loading from "../../assets/loading.gif";
import { ERROR_MSG } from "../../modules/access";

import { css } from "emotion";

const initialState = {
  email: "",
  password: "",
  accessCode: "",
  validInput: true,
  emailValid: true
};

class AddAccess extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.formPreventDefault = this.formPreventDefault.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleProceed = this.handleProceed.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleAccessCode = this.handleAccessCode.bind(this);
  }

  formPreventDefault(e) {
    e.preventDefault();
  }

  hasValidInputs() {
    let fields = ["email", "password", "accessCode"];
    let emptyFields = fields.filter(field => {
      return this.state[field].trim() === "";
    });
    return emptyFields.length === 0;
  }

  validateEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleEmail(event) {
    const email = event.target.value;
    const emailValid = this.validateEmail(email);
    this.setState({ email, emailValid, validInput: true });
  }

  handlePassword(event) {
    const password = event.target.value;
    this.setState({
      password,
      validInput: true,
      hasPassword: true
    });
  }

  handleAccessCode(event) {
    const accessCode = event.target.value;
    this.setState({
      accessCode,
      validInput: true,
      hasAccessCode: true
    });
  }

  getServerError() {
    const status = this.props.status;
    if (status === ERROR_MSG.LOGIN_FAILURE) {
      return "Invalid email address or password";
    } else if (status === ERROR_MSG.ACCESS_REQUEST_FAILED) {
      return "Invalid access code";
    }
    return null;
  }

  getValidationErrors() {
    const { validInput, emailValid } = this.state;
    if (!validInput) {
      return "Please enter all the fields";
    }
    if (!emailValid) {
      return "Please enter a valid email address";
    }
    return null;
  }

  handleProceed() {
    const errorMessage = this.getValidationErrors();
    const hasValidInputs = this.hasValidInputs();
    this.setState({
      validInput: hasValidInputs
    });
    if (hasValidInputs && errorMessage === null) {
      this.props.onSubmit(
        this.state.email.toLowerCase(),
        this.state.password,
        this.state.accessCode
      );
    }
  }

  renderProceedButton() {
    const containerStyle = css({
      margin: "15",
      display: "flex",
      flex: "1",
      alignSelf: "center",
      width: "100%",
      justifyContent: "center"
    });

    const container = css({
      backgroundColor: "#0151cb",
      width: "100%"
    });

    const buttonContainer = css({
      width: "100%"
    });

    const showLoader = this.props.inProgress;
    const loader = <img src={loading} alt="loading" className={loadingClass} />;
    const proceedButton = (
      <Button
        label="Proceed"
        buttonClickCallback={this.handleProceed}
        text="Proceed"
        btnClassName={container}
        buttonContainerStyle={buttonContainer}
      />
    );
    return (
      <div className={containerStyle}>
        {showLoader ? loader : proceedButton}
      </div>
    );
  }

  renderError(message = "Please enter all the fields.") {
    const textStyle = css({
      marginTop: "auto",
      marginBottom: "auto",
      display: "flex",
      textAlign: "left",
      fontFamily: "OpenSans",
      fontSize: "14px",
      fontWeight: "600",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "1.5",
      letterSpacing: "normal",
      color: "#ff5630"
    });
    const container = css({
      display: "flex",
      border: "solid 1px #ff5630",
      padding: "10px 12px",
      borderRadius: "4px",
      backgroundColor: "#ffebe5",
      minHeight: "30px"
    });
    if (message === null) return null;
    return (
      <div className={container}>
        <div className={textStyle}>{message}</div>
      </div>
    );
  }

  render() {
    const serverErrors = this.getValidationErrors();
    const validationErrors = this.getServerError();
    const errorMessage =
      serverErrors !== null ? serverErrors : validationErrors;
    return (
      <div>
        <form onSubmit={this.formPreventDefault}>
          <div className={divStyle}>
            {this.renderError(errorMessage)}
            <br />
            <TextField
              labelType="floating"
              label="Email address"
              value=""
              onChange={this.handleEmail}
            />
            <br />
            <TextField
              type="password"
              labelType="floating"
              label="Password"
              value=""
              onChange={this.handlePassword}
            />
            <br />
            <TextField
              labelType="floating"
              label="Access code"
              value=""
              onChange={this.handleAccessCode}
            />
            <br />
            {this.renderProceedButton()}
          </div>
        </form>
      </div>
    );
  }
}

const divStyle = css({
  textAlign: "center",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column"
});

const loadingClass = css({
  height: "50px",
  width: "50px"
});

export default AddAccess;
