import React, { Component } from 'react';
import Modal from '../modal/modal';
import { css } from 'emotion';
import icon from '../../assets/reg_success.png';

class RegisterSuccess extends Component {

  render(){
    return (
      <div className={container}>
        <Modal title="" subtitle="">
          <div className={contentContainer}>
            <div className={imageStyle}>
              <img src={icon} alt="Success" />
            </div>
            <span className={modalTitle}>{'Welcome to Fybr!'}</span>
            <span className={modalSubTitle}>{'Your registration has completed successfully.'}</span>
            <span className={nextLine}>{'You may now use these credentials to log in to Fybr applications.'}</span>
          </div>
        </Modal>
      </div>
    );
  }

}

const container = css({
  backgroundColor: '#202e38',
  position: 'relative',
});

const contentContainer = css({
  display: 'flex',
  flexDirection: 'column'
});

const imageStyle = css({
  flexGrow: '2'
});

const modalTitle = css({
  fontFamily: 'OpenSans',
  fontSize: '36px',
  fontWeight: 300,
  lineHeight: 1.5,
  letterSpacing: '0.5px',
  textAlign: 'center',
  color: '#212121',
  flexGrow: '1'
});

const modalSubTitle = css({
  fontFamily: 'OpenSans',
  fontSize: '16px',
  lineHeight: 1.5,
  letterSpacing: '0.5px',
  display: 'block',
  marginTop: '8px',
  color: '#112138',
  flexGrow: '1'
});

const nextLine = css({
  fontFamily: 'OpenSans',
  fontSize: '16px',
  lineHeight: 1.5,
  letterSpacing: '0.5px',
  display: 'block',
  marginBottom: '40px',
  color: '#112138',
  flexGrow: '1'
});

export default RegisterSuccess;
