import React from 'react';
import Icon from 'react-fa';
import PropTypes from 'prop-types';
import { css } from 'emotion';

export default class Button extends React.Component {

  static propTypes = {
    buttonContainerStyle: PropTypes.string,
    buttonStyle: PropTypes.object,
    buttonTextStyle: PropTypes.object,
    buttonClickCallback: () => {},
    btnClassName: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    iconStyle: PropTypes.object
  };

  static defaultProps = { };

  render() {
    const {
      buttonContainerStyle,
      btnClassName,
      text,
      icon,
      iconStyle
     } = this.props;

    return (
      <div className={buttonContainerStyle}>
        <button
          className={defaultButtonClass + ' ' + btnClassName}
          onClick={this._buttonClick}
          >
          {text ?
            <span className={spanClass} >{text}</span>
            : icon ?
            <Icon name={icon} className="icon" style={iconStyle}/>
          : null}
        </button>
      </div>
    );
  }

  _buttonClick = () => {
    this.props.buttonClickCallback();
  }
}

const defaultButtonClass = css({
  background: '#0151cb',
  border: 'none',
  fontFamily: 'OpenSans-Bold',
  color: '#000000',
  position: 'relative',
  margin: 0,
  minWidth: '64px',
  padding: '14px 35px',
  display: 'inline-block',
  overflow: 'hidden',
  willChange: 'box-shadow',
  transition: 'box-shadow 0.15s cubic-bezier(0.4, 0, 1, 1),background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1),color 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
  outline: 'none',
  cursor: 'pointer',
  textDecoration: 'none',
  textAlign: 'center',
  verticalAlign: 'middle',
  borderRadius: '2px',
  textTransform: 'uppercase',
  '&::-moz-focus-inner': {
    border: 0
  },
  '&:hover': {
    backgroundColor: '#0151cb'
  },
  '&:active': {
    backgroundColor: '#0151cb'
  }
});

const spanClass = css({
  color: '#FFFFFF',
  fontSize: '14px',
  letterSpacing: '1px',
  lineHeight: '21px',
});
